export default function useDateRange(dates) {
  const startTimestamp = dates[0].start_at.timestamp;
  const endTimestamp = dates[dates.length - 1].end_at.timestamp;

  const startDate = startTimestamp ? useDateFormat(startTimestamp) : null;
  const shortStartDate = startTimestamp ? useDateFormatShort(startTimestamp) : null;
  const endDate = endTimestamp ? useDateFormat(endTimestamp) : null;

  if (startDate === endDate) {
    return endDate;
  } else {
    const yearStart = new Date(startTimestamp * 1000).getFullYear(); // Převedení timestamp na Date a získání roku
    const yearEnd = new Date(endTimestamp * 1000).getFullYear(); // Převedení timestamp na Date a získání roku
    if (yearStart === yearEnd) {
      return `${shortStartDate}–${endDate}`;
    } else {
      return `${startDate}–${endDate}`;
    }
  }
}
